import React, { useLayoutEffect } from "react";
import * as CoverStyles from "./Cover.module.scss";
import HF1 from "../../../../assets/images/hf-1.png";
import HF2 from "../../../../assets/images/hf-2.png";
import HF3 from "../../../../assets/images/hf-3.png";
import { FadeInUPTextAnimation } from "../../../../../GSAPSnippets";

const hallOfFameCoverImgs = [
  {
    img: HF1,
  },
  {
    img: HF2,
  },
  {
    img: HF3,
  },
];

const Cover = () => {
  useLayoutEffect(() => {
    FadeInUPTextAnimation("HoFCoverText", 1.2);
  }, []);

  return (
    <section className={`es-section__light ${CoverStyles.CoverWrapper}`}>
      <div className="HoFCoverText">
        <div className={CoverStyles.CoverUpperText}>
          <h1>Hall of Fame</h1>
          <p>
            We have quite a few stars gracing the stage of our own as well.
            Here’s a brief ode to their spectacle.
          </p>
        </div>

        <div className={CoverStyles.CoverGallery}>
          {hallOfFameCoverImgs.map((item, index) => (
            <div key={index} className={CoverStyles.CoverGalleryImg}>
              <img src={item.img} alt="Hall of fame" />
            </div>
          ))}
        </div>

        <div className={CoverStyles.CoverLowerText}>
          <h4>
            Yes! We have a client type.
            <br></br>
            We only work with like-minded businesses that want to last
            long-term.
          </h4>

          <p>
            We have worked with leading international
            <strong>
              SaaS products, DaaS products, cloud technologies, digital
              services,
            </strong>
            &nbsp; and more. They all had one thing in common: the ambition to
            succeed in the future and today.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Cover;
