import React, { useState, Fragment, useRef, useLayoutEffect } from "react";
import * as HallOfFameGalleryStyles from "./HallOfFameGallery.module.scss";
import team from "../../../../assets/images/team-pic.png";
import team1 from "../../../../assets/images/team-1.png";
import team2 from "../../../../assets/images/team-2.png";
import team3 from "../../../../assets/images/team-3.png";
import team4 from "../../../../assets/images/team-4.png";
import team5 from "../../../../assets/images/team-5.png";
import { Link } from "gatsby";
import { Close } from "../../../SVGs/SVGs";

import { gsap } from "gsap";
import {
  FadeInUPTextAnimationWithTrigger_ref,
  FadeInUPTextAnimationWithTrigger,
} from "../../../../../GSAPSnippets";

const teamGalleryData = [
  {
    img: team1,
    name: "Taimoor Raza",
    designation: "Full Stack Developer",
    isBeingViewed: false,
    bio: "Specializing in IoT-based, cloud-based, desktop-based, web-based,  full-stack developers with more than 500 apprentices working with him worldwide.",
    hobbies: [
      {
        hobby: <li>📚 &nbsp;&nbsp; Books</li>,
      },
      {
        hobby: <li>⚽ &nbsp;&nbsp; Soccer</li>,
      },
      {
        hobby: <li>🗺️ &nbsp;&nbsp; Traveling</li>,
      },
    ],
    challengesTitle: "Challenges he loves",
    challengesDesc:
      "He loves working on complex tech projects that require excessive research and transformative writing to attract clients. ",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
    ],
  },
  {
    img: team2,
    name: "Osama",
    designation: "Program Manager",
    isBeingViewed: false,
    bio: "Specializing in IoT-based, cloud-based, desktop-based, web-based, him worldwide.",
    hobbies: [
      {
        hobby: <li>📚 &nbsp;&nbsp; Books</li>,
      },
      {
        hobby: <li>⚽ &nbsp;&nbsp; Soccer</li>,
      },
    ],
    challengesTitle: "Challenges he loves",
    challengesDesc:
      "He loves working on complex tech projects that require excessive research and transformative writing to attract clients. ",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
    ],
  },
  {
    img: team3,
    name: "Ali Hasan",
    designation: "Product Manager",
    isBeingViewed: false,
    bio: "Specializing in IoT-based, cloud-based, desktop-based, web-based",
    hobbies: [
      {
        hobby: <li>⚽ &nbsp;&nbsp; Soccer</li>,
      },
      {
        hobby: <li>🗺️ &nbsp;&nbsp; Traveling</li>,
      },
    ],
    challengesTitle: "Challenges he loves",
    challengesDesc:
      "He loves working on complex tech projects that require excessive research and transformative writing to attract clients. ",
    profiles: [],
  },
  {
    img: team4,
    name: "Arsalan Ahmad",
    designation: "Project Manager",
    isBeingViewed: false,
    bio: "He is one of the world’s top 1% full-stack developers with more than 500 apprentices working with him worldwide.",
    hobbies: [
      {
        hobby: <li>⚽ &nbsp;&nbsp; Soccer</li>,
      },
      {
        hobby: <li>🗺️ &nbsp;&nbsp; Traveling</li>,
      },
    ],
    challengesTitle: "Challenges he loves",
    challengesDesc:
      "He loves working on complex tech projects that require excessive research and transformative writing to attract clients. ",
    profiles: [],
  },
  {
    img: team5,
    name: "Khunsha Javed",
    designation: "Content Branding",
    isBeingViewed: false,
    bio: "Specializing in IoT-based, cloud-based, desktop-based, web-based.",
    hobbies: [
      {
        hobby: <li>📚 &nbsp;&nbsp; Books</li>,
      },

      {
        hobby: <li>🗺️ &nbsp;&nbsp; Traveling</li>,
      },
    ],
    challengesTitle: "Challenges she loves",
    challengesDesc:
      "she loves working on complex tech projects that require excessive research and transformative writing to attract clients. ",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
    ],
  },
  {
    img: team,
    name: "Saad Waseem",
    designation: "Full Stack Engineer",
    isBeingViewed: false,
    bio: "Specializing in IoT-based, cloud-based, desktop-based, web-based, and mobile-based solutions, he is one of the world’s top 1% full-stack developers with more than 500 apprentices working with him worldwide.",
    hobbies: [
      {
        hobby: <li>📚 &nbsp;&nbsp; Books</li>,
      },
      {
        hobby: <li>⚽ &nbsp;&nbsp; Soccer</li>,
      },
    ],
    challengesTitle: "Challenges he loves",
    challengesDesc:
      "He loves working on complex tech projects that require excessive research and transformative writing to attract clients. ",
    profiles: [
      {
        name: "linkedin",
        link: "/",
      },
    ],
  },
];

const HallOfFameGallery = () => {
  const HoFWrapperRef = useRef();

  const [isDetailsVisible, setisDetailsVisible] = useState(false);
  const [teamGallery, setTeamGallery] = useState(teamGalleryData);
  const [itemDetails, setItemDetails] = useState();
  const [intialLoad, setintialLoad] = useState(true);

  const HoFTeamRefs = useRef([]);
  HoFTeamRefs.current = [];

  const addToHoFTeamRef = (el) => {
    if (el && !HoFTeamRefs.current.includes(el)) {
      HoFTeamRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    if (intialLoad) {
      HoFTeamRefs.current.forEach((el) => {
        FadeInUPTextAnimationWithTrigger_ref("HoFAnimateContent", el);
      });
      FadeInUPTextAnimationWithTrigger("HoFTeamTitleText", "HoFGallerySection");
    }
    setintialLoad(false);
  });

  const viewDetailsHandler = (index, item) => {
    setisDetailsVisible(true);
    setItemDetails(item);

    const updatedTeamGallery = [...teamGallery];
    updatedTeamGallery.forEach((teamItem) => {
      teamItem.isBeingViewed = false;
    });
    updatedTeamGallery[index] = {
      ...updatedTeamGallery[index],
      isBeingViewed: true,
    };

    setTeamGallery(updatedTeamGallery);

    gsap.fromTo(
      ".HoFAnim",
      {
        opacity: 0,
        y: 100,
      },
      {
        duration: 1,
        opacity: 1,
        y: 0,
        delay: 0.3,
        stagger: 0.1,
      }
    );
  };

  const hideDetailsHandler = () => {
    setisDetailsVisible(false);
    setItemDetails("");
  };

  return (
    <section
      className={`HoFGallerySection ${HallOfFameGalleryStyles.HallOfFameGalleryWrapper}`}
    >
      <div>
        <h2 className="HoFTeamTitleText">
          Meet the people that make performance possible
        </h2>

        <div
          className={`${
            isDetailsVisible ? HallOfFameGalleryStyles.active : ""
          } ${HallOfFameGalleryStyles.HallOfFameGalleryGrid}`}
        >
          {teamGallery.map((item, index) => (
            <div
              ref={addToHoFTeamRef}
              key={index}
              className={`es-tg-item ${
                item.isBeingViewed ? HallOfFameGalleryStyles.activeItem : ""
              } ${HallOfFameGalleryStyles.teamGalleryGridItem}`}
            >
              <div
                className={`es-tg-img HoFAnimateContent`}
                onClick={() => viewDetailsHandler(index, item)}
              >
                <img src={item.img} alt="" />
              </div>
              <div
                className={`es-tg-text HoFAnimateContent ${HallOfFameGalleryStyles.teamGalleryGridItemText}`}
              >
                <p> {item.designation}</p>
                <h4>{item.name}</h4>
                <ul>
                  {item.profiles?.map((profile, profIndex) => (
                    <li key={profIndex}>
                      <Link to={profile.link}>{profile.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div
          ref={HoFWrapperRef}
          className={`${
            isDetailsVisible ? HallOfFameGalleryStyles.active : ""
          } ${HallOfFameGalleryStyles.HallOfFameItemDetails}`}
        >
          <div>
            <button onClick={hideDetailsHandler}>
              <Close />
            </button>

            <div className={HallOfFameGalleryStyles.HallOfFameItemDetailsGrid}>
              <div className={HallOfFameGalleryStyles.HallOfFameItemDetailsCol}>
                <span className="HoFAnim es-subtitle-text">
                  {itemDetails?.designation}
                </span>
                <h3 className="HoFAnim">{itemDetails?.name}</h3>
                <p className="HoFAnim">{itemDetails?.bio}</p>
              </div>
              <div className={HallOfFameGalleryStyles.HallOfFameItemDetailsCol}>
                <div className={HallOfFameGalleryStyles.challengesWrapper}>
                  <h5 className="HoFAnim">{itemDetails?.challengesTitle}</h5>
                  <p className="HoFAnim">{itemDetails?.challengesDesc}</p>
                </div>
                {itemDetails?.profiles?.length > 0 && (
                  <span className="HoFAnim es-subtitle-text">
                    Let’s get social
                  </span>
                )}
                <ul className="HoFAnim">
                  {itemDetails?.profiles?.map((profile, index) => (
                    <li key={index}>
                      <Link to="/">{profile.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className={`HoFAnim ${HallOfFameGalleryStyles.HallOfFameItemDetailsHobbies}`}
            >
              <span>Hobbies</span>
              <ul>
                {itemDetails?.hobbies?.map((hobby, index) => (
                  <Fragment key={index}>{hobby.hobby}</Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HallOfFameGallery;
