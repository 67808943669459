// extracted by mini-css-extract-plugin
export var HallOfFameGalleryWrapper = "HallOfFameGallery-module--HallOfFameGalleryWrapper--An8Ev";
export var HallOfFameGalleryGrid = "HallOfFameGallery-module--HallOfFameGalleryGrid--C4eGd";
export var active = "HallOfFameGallery-module--active--wvI3J";
export var teamGalleryGridItem = "HallOfFameGallery-module--teamGalleryGridItem--+N5ci";
export var activeItem = "HallOfFameGallery-module--activeItem--bQ0EO";
export var teamGalleryGridItemText = "HallOfFameGallery-module--teamGalleryGridItemText--d0phs";
export var HallOfFameItemDetails = "HallOfFameGallery-module--HallOfFameItemDetails--38fjk";
export var HallOfFameItemDetailsGrid = "HallOfFameGallery-module--HallOfFameItemDetailsGrid--kYzJW";
export var HallOfFameItemDetailsCol = "HallOfFameGallery-module--HallOfFameItemDetailsCol--7NgzP";
export var challengesWrapper = "HallOfFameGallery-module--challengesWrapper--5FAx4";
export var HallOfFameItemDetailsHobbies = "HallOfFameGallery-module--HallOfFameItemDetailsHobbies--WIFEo";